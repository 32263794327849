import React, { useState } from "react"

import { Link, graphql } from "gatsby"

import number_format from "../helpers/numberformat"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Menu from "../components/menu"
import ContactForm from "../components/contactform"

export default function ProductPage ({ data }) {
    const product = data.prismicProducts.data;
    const [activeImage, setActiveImage] = useState(product.product_images[0].image.url);

    return (
        <Layout>
            <SEO title="Antikviteter, Lyxiga klockor, smycken & antika tavlor" />
            
            <Menu top={true} />
            
            <section className="product-overview mt-0 mt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="product">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="image">
                                            {product.sold == true && <div className="sold">Sold</div>}
                                            <img src={activeImage} className="img-fluid w-100" alt="" />

                                            <div className="form-row">
                                                {product.product_images.map((a, index) => 
                                                    <div className="col-4">
                                                        <img 
                                                            src={a.image.url} 
                                                            onClick={() => setActiveImage(a.image.url)} 
                                                            className="img-fluid w-100" 
                                                            alt="" 
                                                            style={{ cursor: 'pointer' }} 
                                                            key={index} 
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6">
                                        <div className="information p-3 p-lg-5 bg-light">
                                            <h2 children={product.name.text} />
                                            <div className="category" children={product.category} />
                                            {parseInt(product.price) > 0 && (
                                                <div className="price" children={`${number_format(product.price, '', '', ' ')} kr`} />
                                            )}
                                            <div className="description" dangerouslySetInnerHTML={{ __html: product.description.text }} />
                                            {data.prismicProducts.dataRaw.proveniens.length > 0 && (
                                                <>
                                                    <hr />
                                                    <strong className="mt-5">Proviens:</strong>
                                                    {data.prismicProducts.dataRaw.proveniens.map((a) => {
                                                        return (<div className="description" dangerouslySetInnerHTML={{ __html: a.text }} />)
                                                    })}
                                                </>
                                            )}
                                            <div className="cta-interested">
                                                <h3 children={`Är du intresserad av "${product.name.text}" och vill lägga ett bud?`} />
                                                <p>Vänligen ange ditt telefonnr. eller e-mailadress så kontaktar vi dig inom 24 timmar!</p>
                                                <form method="post" name="product-interested" data-netlify="true">
                                                    {/* You still need to add the hidden input with the form name to your JSX form */}
                                                    <input type="hidden" name="form-name" value="product-interested" />
                                                    <input type="hidden" name="product-name" className="form-control" value={product.name.text} />
                                                    <input type="text" name="contact-info" className="form-control" placeholder="E-mail / Telefonnr." />
                                                    <div className="w-100 text-right">
                                                        <input type="submit" className="btn btn-light mt-2" value="Kontakta mig" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactForm 
                text={{ 
                h2: 'Behov av värdering? – Svar inom 24h',
                h3: 'Oavset om det är en stor eller liten pjäs så hjälper vi till med värdering.'
                }} 
            />
            <Footer />
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    prismicProducts(uid: {eq: $slug}) {
        dataRaw
        data {
            category
            description {
                text
            }
            name {
                text
            }
            price
            product_images {
                image {
                    url
                }
            }
            proveniens {
                text
            }
            sold
        }
    }      
  }
`
